import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  icon?: string
  label?: string
}

export const Feature = memo(function Feature({ icon, label }: Props) {
  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <>
          {icon ? <SVG src={icon} width="40" height="40" alt={label} /> : null}
          {label ? <Label>{label}</Label> : null}
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.div`
  width: calc(33.333% - 0.75rem);
  margin: 0.75rem 0.75rem 0 0;
  text-align: center;
  &:nth-of-type(odd) {
    .react-reveal {
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
    }
  }
  &:nth-of-type(even) {
    .react-reveal {
      box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 12px;
    }
  }
  &:nth-of-type(3n + 2) {
    transform: translateY(-2.25rem);
  }

  .react-reveal {
    height: 100%;
    border-radius: 6px;
    padding: 2.8125rem 3.125vw 2.5625rem 3.125vw;
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.75rem);

    &:nth-of-type(3n + 2) {
      transform: none;
    }
  }
`

const SVG = styled.img`
  width: auto;
  height: 40px;
  margin-bottom: 1rem;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.25rem;
`
