import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  facebookURL?: string
  instagramURL?: string
  linkedinURL?: string
}

export const Social = memo(function Social({
  facebookURL,
  instagramURL,
  linkedinURL,
}: Props) {
  return (
    <Container dial={4} row>
      {facebookURL ? (
        <Item
          aria-label="Facebook"
          href={facebookURL}
          rel="noreferrer"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.308"
            height="18"
            viewBox="0 0 8.308 18"
          >
            <path
              d="M45.949,370.42h3.624v-9.077H52.1l.27-3.039h-2.8v-1.73c0-.716.144-1,.837-1h1.962V352.42h-2.51c-2.7,0-3.913,1.187-3.913,3.461V358.3H44.063v3.077h1.886Z"
              transform="translate(-44.063 -352.42)"
            />
          </svg>
        </Item>
      ) : null}
      {instagramURL ? (
        <Item
          aria-label="Instagram"
          href={instagramURL}
          rel="noreferrer"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M-1572.59-7150.3a6.613,6.613,0,0,1-2.184-.417,4.414,4.414,0,0,1-1.594-1.038,4.4,4.4,0,0,1-1.037-1.594,6.589,6.589,0,0,1-.42-2.186c-.043-.96-.053-1.266-.053-3.711s.01-2.75.053-3.711a6.585,6.585,0,0,1,.42-2.184,4.4,4.4,0,0,1,1.037-1.594,4.4,4.4,0,0,1,1.594-1.037,6.569,6.569,0,0,1,2.184-.42c.96-.044,1.268-.053,3.711-.053s2.751.009,3.711.053a6.567,6.567,0,0,1,2.185.42,4.4,4.4,0,0,1,1.594,1.037,4.418,4.418,0,0,1,1.038,1.594,6.617,6.617,0,0,1,.417,2.184c.044.96.055,1.268.055,3.711s-.011,2.751-.055,3.711a6.621,6.621,0,0,1-.417,2.186,4.418,4.418,0,0,1-1.038,1.594,4.415,4.415,0,0,1-1.594,1.038,6.611,6.611,0,0,1-2.185.417c-.96.043-1.267.055-3.711.055S-1571.63-7150.26-1572.59-7150.3Zm.075-16.271a4.957,4.957,0,0,0-1.671.31,2.79,2.79,0,0,0-1.035.673,2.778,2.778,0,0,0-.674,1.035,4.965,4.965,0,0,0-.31,1.671c-.042.949-.052,1.233-.052,3.636s.01,2.688.052,3.637a4.975,4.975,0,0,0,.31,1.671,2.786,2.786,0,0,0,.674,1.034,2.8,2.8,0,0,0,1.035.674,5.019,5.019,0,0,0,1.671.309c.949.043,1.233.052,3.636.052s2.688-.009,3.637-.052a5.027,5.027,0,0,0,1.671-.309,2.8,2.8,0,0,0,1.035-.674,2.8,2.8,0,0,0,.674-1.034,5.011,5.011,0,0,0,.31-1.671c.042-.949.052-1.234.052-3.637s-.01-2.687-.052-3.636a5,5,0,0,0-.31-1.671,2.79,2.79,0,0,0-.674-1.035,2.789,2.789,0,0,0-1.035-.673,4.966,4.966,0,0,0-1.671-.31c-.949-.044-1.234-.052-3.637-.052S-1571.567-7166.619-1572.516-7166.575Zm-.985,7.326a4.622,4.622,0,0,1,4.621-4.621,4.622,4.622,0,0,1,4.622,4.621,4.623,4.623,0,0,1-4.622,4.623A4.622,4.622,0,0,1-1573.5-7159.249Zm1.621,0a3,3,0,0,0,3,3,3,3,0,0,0,3-3,3,3,0,0,0-3-3A3,3,0,0,0-1571.879-7159.249Zm6.724-4.8a1.079,1.079,0,0,1,1.081-1.08,1.08,1.08,0,0,1,1.08,1.08,1.081,1.081,0,0,1-1.08,1.081A1.081,1.081,0,0,1-1565.155-7164.053Z"
              transform="translate(1577.879 7168.248)"
            />
          </svg>
        </Item>
      ) : null}
      {linkedinURL ? (
        <Item
          aria-label="Instagram"
          href={linkedinURL}
          rel="noreferrer"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.99"
            height="18"
            viewBox="0 0 17.99 18"
          >
            <path
              d="M-1518.061-7099.554v-6.3c0-1.727-.656-2.692-2.021-2.692-1.487,0-2.263,1-2.263,2.692v6.3h-3.563v-12h3.563v1.615a4.184,4.184,0,0,1,3.616-1.981c2.543,0,4.365,1.553,4.365,4.768v7.6Zm-13.937,0v-12h3.715v12Zm-.357-15.786a2.206,2.206,0,0,1,2.2-2.214,2.205,2.205,0,0,1,2.2,2.214,2.206,2.206,0,0,1-2.2,2.215A2.206,2.206,0,0,1-1532.354-7115.34Z"
              transform="translate(1532.354 7117.554)"
            />
          </svg>
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`

const Item = styled.a`
  margin-right: 1.625rem;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }
  svg {
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    transition: 0.1s ease-in-out;
  }

  @media (max-width: 1023px) {
    margin: 0 0.8125rem;
  }
`
