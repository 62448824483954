import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

import { Option, Props as OptionProps } from './Option'

export interface Props {
  disabledFirst?: string
  name: string
  error?: boolean
  options: OptionProps[]
  required?: boolean
  defaultValue?: string
  onBlur: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  onFocus?: FocusEventHandler<HTMLSelectElement>
}
export const Select = memo(
  forwardRef<HTMLSelectElement, Props>(function Select(
    {
      disabledFirst,
      name,
      error = false,
      options,
      required,
      defaultValue,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        error={error}
        name={name}
        required={required}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      >
        {disabledFirst ? <Option value={disabledFirst} disabled /> : null}
        {options.map((item, index) => (
          <Option key={index} {...item} />
        ))}
      </Container>
    )
  }),
)

Select.displayName = 'Select'

const Container = styled.select<ContainerProps>`
  ${({ theme, error }) => {
    return css`
      width: 100%;
      height: 3.25rem;
      font-family: ${theme.fontFamily.heading};
      font-size: 0.8125rem;
      font-weight: 600;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14.251' height='8' viewBox='0 0 14.251 8'><path d='M7.744,7.744l6.251-6.229a.9.9,0,0,0,0-1.259.9.9,0,0,0-1.259,0L7.125,5.867,1.515.256a.9.9,0,0,0-1.259,0,.9.9,0,0,0,0,1.259L6.485,7.744A.9.9,0,0,0,7.744,7.744Z' fill='lightgray' fillRule='evenodd'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 50%;
      border: 1px solid
        ${error
          ? theme.colors.variants.dangerLight1
          : theme.colors.variants.neutralLight2};
      color: ${theme.colors.variants.neutralDark4};
      border-radius: 6px;
      cursor: pointer;
      margin-top: 0.75rem;
      padding: 0 1.125rem;

      @media (max-width: 767px) {
        background-position-x: 96%;
      }
    `
  }}
`
interface ContainerProps {
  error: boolean
}
