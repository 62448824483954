import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Feature, Props as FeatureProps } from './Feature'

export interface Props {
  buttonLabel?: string
  description?: string
  features?: FeatureProps[]
}

export const FeaturesList = memo(function FeaturesList({
  buttonLabel,
  features,
  description,
}: Props) {
  const lines = [1, 2, 3, 4, 5, 6, 7, 8]

  return (
    <Container dial={5} tag="section" row stretch>
      <LeftSide dial={4}>
        <BackgroundLines>
          {lines.map((item, index) => (
            <Line key={index} />
          ))}
        </BackgroundLines>
        {description ? (
          <Fade left>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </Fade>
        ) : null}
        {buttonLabel ? (
          <Fade left>
            <CTA label={buttonLabel} />
          </Fade>
        ) : null}
      </LeftSide>
      <RightSide>
        {features && features.length > 0 ? (
          <Features row stretch wrap>
            {features?.map((item, index) => (
              <Feature key={index} {...item} />
            ))}
          </Features>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 9rem auto;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    margin: 5.625rem auto;
  }
`

const LeftSide = styled(FlexBox)`
  width: 37%;
  overflow: hidden;
  padding-right: 6.25vw;
  padding-left: 10vw;
  position: relative;

  @media (max-width: 1199px) {
    padding-left: 4.861vw;
  }

  @media (max-width: 1023px) {
    width: auto;
    padding: 0 1.5rem;
  }
`

const BackgroundLines = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @media (max-width: 1023px) {
    display: none;
  }
`

const Line = styled.div`
  display: none;
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 3.75rem;
  &:first-of-type {
    width: 70%;
    margin-top: 0;
  }
  &:nth-of-type(2) {
    width: 80%;
  }
  &:nth-of-type(3) {
    width: 90%;
  }
  &:nth-of-type(5) {
    width: 15%;
  }
  &:nth-of-type(6) {
    width: 50%;
  }
  &:nth-of-type(7) {
    width: 70%;
  }
  &:nth-of-type(8) {
    width: 40%;
  }
`

const Description = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 2.8125rem;

  b,
  strong {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
    font-weight: 600;
  }
`

const CTA = styled(Button)`
  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled.div`
  width: 63%;
  padding-right: calc(10vw - 0.75rem);

  @media (max-width: 1199px) {
    padding-right: calc(4.861vw - 0.75rem);
  }

  @media (max-width: 1023px) {
    width: auto;
    margin-top: 3.75rem;
    padding: 0 0.75rem 0 1.5rem;
  }
`

const Features = styled(FlexBox)`
  padding-top: 1.5rem;
`
