import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  brandIcon?: string
  services?: ServiceProps[]
  title?: string
}

export const ServicesList = memo(function ServicesList({
  brandIcon,
  services,
  title,
}: Props) {
  return (
    <Container>
      {brandIcon ? (
        <Fade bottom distance="3.75rem">
          <Brand src={brandIcon} width="40" height="46" alt="Zucchetti" />
        </Fade>
      ) : null}
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title dangerouslySetInnerHTML={{ __html: title }} />
        </Fade>
      ) : null}
      {services && services.length > 0 ? (
        <Services>
          {services?.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 9rem auto 0;
  padding: 5rem 10vw 9rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding-right: 4.861vw;
    padding-left: 4.861vw;
  }

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
    padding: 5.625rem 1.5rem;
  }
`

const Brand = styled.img`
  margin-bottom: 0.75rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 3.75rem;
  b,
  strong {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
    font-weight: 600;
  }
`

const Services = styled.div``
