import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  description?: string
  image?: ImageProps
  languageCode: string
  logo?: ImageProps
  subtitle?: string
  title?: string
}

export const Service = memo(function Service({
  description,
  image,
  languageCode,
  logo,
  subtitle,
  title,
}: Props) {
  return (
    <Container dial={4} row stretch wrap>
      <LeftSide className="services-text" dial={4}>
        {title ? (
          <Fade bottom distance="3.75rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {subtitle ? (
          <Fade bottom distance="3.75rem">
            <Subtitle>{subtitle}</Subtitle>
          </Fade>
        ) : null}
        {description ? (
          <Fade bottom distance="3.75rem">
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </Fade>
        ) : null}
        <Fade bottom distance="3.75rem">
          <Button label={useVocabularyData('find-out-more', languageCode)} />
        </Fade>
      </LeftSide>
      <RightSide className="services-images">
        <Fade>
          <LazyLoad>
            <Background>
              {image ? <Image {...image} /> : null}
              {logo ? (
                <Logo>
                  <Image {...logo} />
                </Logo>
              ) : null}
            </Background>
          </LazyLoad>
        </Fade>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 9rem;
  &:first-of-type {
    margin-top: 7.5rem;
  }
  &:nth-of-type(even) {
    .services-text {
      order: 2;
      padding-right: 0;
      padding-left: 8.333vw;

      @media (max-width: 1199px) {
        padding-left: 6.25vw;
      }

      @media (max-width: 1023px) {
        padding-left: 1.875rem;
      }

      @media (max-width: 767px) {
        padding: 0;
      }
    }
    .services-images {
      padding-right: 8.333vw;
      padding-left: 0;

      @media (max-width: 1199px) {
        padding-right: 6.25vw;
      }

      @media (max-width: 1023px) {
        padding-right: 1.875rem;
      }

      @media (max-width: 767px) {
        padding: 0;
      }
    }
  }

  @media (max-width: 1023px) {
    margin-top: 5.625rem;
    &:first-of-type {
      margin-top: 5.625rem;
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-right: 8.333vw;
  text-align: left;

  @media (max-width: 1199px) {
    padding-right: 6.25vw;
  }

  @media (max-width: 1023px) {
    padding-right: 1.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    margin-top: 3.75rem;
    padding: 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 3.75rem;
`

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.1875rem;
  font-weight: 600;
  line-height: 1.8125rem;
  margin-top: 0.25rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 1.75rem;
`

const RightSide = styled.div`
  width: 50%;
  padding-left: 8.333vw;

  @media (max-width: 1199px) {
    padding-left: 6.25vw;
  }

  @media (max-width: 1023px) {
    padding-left: 1.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`

const Background = styled.div`
  border-radius: 6.25vw;
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.4)} 0 10px 30px;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 50%;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 3.472vw;
  overflow: hidden;
  padding: 0 1.5rem;
  position: absolute;
  top: 7.847vw;
  right: 7.847vw;
  bottom: 7.847vw;
  left: 7.847vw;
  img {
    max-width: 100%;
    height: auto;
  }
`
