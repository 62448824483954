import styled from '@emotion/styled'
import { CookieMessage } from 'app/components/Common/CookieMessage'
import { Toolbars } from 'app/components/Common/Toolbars'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga'

import { Social } from './Social'

export interface Props {
  facebookURL?: string
  instagramURL?: string
  languageCode: string
  languagePrefix?: string | null
  linkedinURL?: string
  logo?: string
  phone?: string
  siteName?: string
  vat?: string
}

export const Footer = memo(function Footer({
  facebookURL,
  instagramURL,
  languageCode,
  languagePrefix,
  linkedinURL,
  logo,
  phone,
  siteName,
  vat,
}: Props) {
  const year = new Date()

  return (
    <Container>
      <Wrapper>
        <Head>
          {logo ? (
            <Logo src={logo} width="150" height="36" alt={siteName} />
          ) : null}
          {phone ? (
            <Phone
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  ReactGA.event({
                    category: 'Landing',
                    action: 'Click',
                    label: 'Mobile Phone',
                  })
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.969"
                height="16"
                viewBox="0 0 15.969 16"
              >
                <path
                  d="M18.08,13.979v2.409A1.605,1.605,0,0,1,16.48,18c-.051,0-.1,0-.151-.006A15.888,15.888,0,0,1,9.4,15.529a15.653,15.653,0,0,1-4.817-4.817A15.888,15.888,0,0,1,2.118,3.75,1.606,1.606,0,0,1,3.573,2.006C3.621,2,3.669,2,3.716,2H6.125A1.605,1.605,0,0,1,7.731,3.381a10.3,10.3,0,0,0,.562,2.256,1.605,1.605,0,0,1-.361,1.694l-1.02,1.02a12.846,12.846,0,0,0,4.817,4.817l1.02-1.02a1.605,1.605,0,0,1,1.694-.361,10.3,10.3,0,0,0,2.256.562A1.606,1.606,0,0,1,18.08,13.979Z"
                  transform="translate(-2.112 -2)"
                />
              </svg>
              {phone}
            </Phone>
          ) : null}
          <Social
            facebookURL={facebookURL}
            instagramURL={instagramURL}
            linkedinURL={linkedinURL}
          />
        </Head>
        <Bottom dial={2} row space="between" wrap>
          <Policy row wrap>
            <Link
              to={`${
                languagePrefix ? `/${languagePrefix}` : ''
              }/privacy-policy`}
            >
              {useVocabularyData('privacy-policy', languageCode)}
            </Link>
            <Link
              to={`${languagePrefix ? `/${languagePrefix}` : ''}/cookie-policy`}
            >
              {useVocabularyData('cookie-policy', languageCode)}
            </Link>
          </Policy>
          <Info>
            © {year.getFullYear()} - Zucchetti s.p.a
            {vat ? (
              <Vat>{` - ${useVocabularyData(
                'vat',
                languageCode,
              )} ${vat} - `}</Vat>
            ) : null}
            {useVocabularyData('all-rights-reserved', languageCode)}
          </Info>
          <Credits href="https://www.qnt.it" target="_blank" rel="noopener">
            webdesign <b>QNT Hospitality</b>
          </Credits>
        </Bottom>
      </Wrapper>
      <CookieMessage
        languageCode={languageCode}
        languagePrefix={languagePrefix}
      />
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadVertical">
          <Toolbars
            languageCode={languageCode}
            languagePrefix={languagePrefix || null}
            phone={phone}
          />
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  position: relative;
`

const Wrapper = styled.div`
  margin-top: -10.8125rem;
  padding: 5rem 10vw 3rem;

  @media (max-width: 1199px) {
    padding-right: 4.861vw;
    padding-left: 4.861vw;
  }

  @media (max-width: 1023px) {
    margin-top: 0;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    text-align: center;
  }
`

const Head = styled.div``

const Logo = styled.img`
  display: block;

  @media (max-width: 1023px) {
    margin: auto;
  }
`

const Phone = styled.a`
  display: inline-flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.3125rem;
  margin-top: 2.25rem;
  &:hover {
    text-decoration: underline;
  }
  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-right: 0.75rem;
    transform: translateY(2px);
  }
`

const Bottom = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.8125rem;
  line-height: 1.625rem;
  margin-top: 5rem;

  @media (max-width: 1023px) {
    display: block;
  }
`

const Policy = styled(FlexBox)`
  a {
    font-weight: 600;
    margin-right: 1.875rem;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1023px) {
    justify-content: center;
    a {
      margin: 0 0.9375rem;
    }
  }
`

const Info = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};

  @media (max-width: 1023px) {
    margin: 0.75rem auto;
  }
`

const Vat = styled.span``

const Credits = styled.a`
  b {
    font-weight: 600;
  }
`
