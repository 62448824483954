import styled from '@emotion/styled'
import { Footer } from 'app/components/Common/Footer'
import { GoogleAnalytics } from 'app/components/Common/GoogleAnalytics'
import { Header } from 'app/components/Common/Header'
import { ContactsForm } from 'app/components/ContactsForm'
import { CustomerReviews } from 'app/components/CustomerReviews'
import { FeaturesList } from 'app/components/FeaturesList'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { SEO } from 'app/components/SEO'
import { ServicesList } from 'app/components/ServicesList'
import { Video } from 'app/components/Video'
import {
  ContactsFormSenderBackend,
  ContactsFormSenderBackendConfiguration,
} from 'app/utils/ContactsFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useEffect, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  contactsFormSenderBackendConfiguration: ContactsFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function CateringPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  useEffect(() => {
    history.pushState(null, document.title, null)
    window.addEventListener('popstate', function (e) {
      history.pushState(null, document.title, null)
    })
  }, [])

  const context = pageContext.props as any
  const sendFormToBackend = useMemo(
    () =>
      ContactsFormSenderBackend(
        pageContext.contactsFormSenderBackendConfiguration,
      ),
    [pageContext.contactsFormSenderBackendConfiguration],
  )

  return (
    <Container>
      {context.googleAnalyticsProps ? (
        <GoogleAnalytics {...context.googleAnalyticsProps} />
      ) : null}
      <SEO {...context.seoProps} />
      {context.headerProps ? <Header {...context.headerProps} /> : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.featuresListProps ? (
        <FeaturesList {...context.featuresListProps} />
      ) : null}
      {context.servicesListProps ? (
        <ServicesList {...context.servicesListProps} />
      ) : null}
      {context.videoProps ? <Video {...context.videoProps} /> : null}
      {context.customerReviewsProps ? (
        <CustomerReviews {...context.customerReviewsProps} />
      ) : null}
      <ContactsForm
        {...(context.contactsFormProps ? context.contactsFormProps : null)}
        onContactsFormSendToBackend={async (data) =>
          await sendFormToBackend(data)
        }
        pageTitle={context.seoProps.title}
      />
      {context.footerProps ? <Footer {...context.footerProps} /> : null}
    </Container>
  )
})

const Container = styled.main``
