import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga'
import { Link } from 'react-scroll'

interface Props {
  languageCode: string
  languagePrefix: string | null
  phone?: string
}

export const Toolbars = memo(function Toolbars({
  languageCode,
  languagePrefix,
  phone,
}: Props) {
  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      dial={5}
      row
      space="between"
      className={scrollDir == 'down' ? 'hidden' : ''}
    >
      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              ReactGA.event({
                category: 'Landing',
                action: 'Click',
                label: 'Mobile Phone',
              })
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.969"
            height="16"
            viewBox="0 0 15.969 16"
          >
            <path
              d="M18.08,13.979v2.409A1.605,1.605,0,0,1,16.48,18c-.051,0-.1,0-.151-.006A15.888,15.888,0,0,1,9.4,15.529a15.653,15.653,0,0,1-4.817-4.817A15.888,15.888,0,0,1,2.118,3.75,1.606,1.606,0,0,1,3.573,2.006C3.621,2,3.669,2,3.716,2H6.125A1.605,1.605,0,0,1,7.731,3.381a10.3,10.3,0,0,0,.562,2.256,1.605,1.605,0,0,1-.361,1.694l-1.02,1.02a12.846,12.846,0,0,0,4.817,4.817l1.02-1.02a1.605,1.605,0,0,1,1.694-.361,10.3,10.3,0,0,0,2.256.562A1.606,1.606,0,0,1,18.08,13.979Z"
              transform="translate(-2.112 -2)"
            />
          </svg>
          <Label>{useVocabularyData('telephone', languageCode)}</Label>
        </Item>
      ) : null}
      <CTA to="section-contacts" spy={true} offset={-62}>
        {useVocabularyData('book-a-demo', languageCode)}
      </CTA>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 6px;
  box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.2)} 0 3px 20px;
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  z-index: 50;
  transition: 0.3s ease-out;
  transform: translateX(-50%);
  &.hidden {
    transform: translate(-50%, 96px);
  }
`

const Item = styled.a`
  display: flex;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 1.25rem;
  text-align: center;
  svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-right: 0.75rem;
    transform: translateY(2px);
  }
`

const CTA = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  padding: 1.25rem;
  text-align: center;
  white-space: nowrap;
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`
