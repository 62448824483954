import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  location?: string
  logo?: ImageProps
  structure?: string
  user?: string
}

export const Review = memo(function Review({
  description,
  location,
  logo,
  structure,
  user,
}: Props) {
  return (
    <Container>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <ReviewInfo>
        {user ? <User>{user}</User> : null}
        {structure ? <Structure>{structure}</Structure> : null}
        {location ? <Location>{` - ${location}`}</Location> : null}
      </ReviewInfo>
    </Container>
  )
})

const Container = styled.div`
  padding: 3rem 5.972vw;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.75rem;
`

const ReviewInfo = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.1875rem;
  font-weight: 600;
  line-height: 29px;
  margin-top: 2.9375rem;
`

const User = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
`

const Structure = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
`

const Location = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
`
