import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'

export interface Props {
  buttonLabel?: string
  claim?: string
  description?: string
  image: ImageProps
}

export const Hero = memo(function Hero({
  buttonLabel,
  claim,
  description,
  image,
}: Props) {
  return (
    <Container>
      <Image {...image} />
      <Wrapper>
        {claim ? <Claim>{claim}</Claim> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {buttonLabel ? <Button label={buttonLabel} /> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc(100% - 1.25rem);
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.25;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: calc(100% - 1.25rem);
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    &:before {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }
`

const Wrapper = styled.div`
  max-width: 50rem;
  margin-top: -1.25rem;
  position: absolute;
  top: 50%;
  right: 10vw;
  left: 10vw;
  z-index: 2;
  transform: translateY(-50%);

  @media (max-width: 1199px) {
    right: 4.861vw;
    left: 4.861vw;
  }

  @media (max-width: 1023px) {
    right: 1.5rem;
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    text-align: center;
  }
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 5.5rem;
  margin-bottom: 0.75rem;

  @media (max-width: 767px) {
    font-size: 3.125rem;
    line-height: 4.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2.125rem;
`
