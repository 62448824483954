import styled from '@emotion/styled'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  brandIcon?: string
  description?: string
  image?: { [key: string]: any }
  title?: string
}

export const Intro = memo(function Intro({
  brandIcon,
  description,
  image,
  title,
}: Props) {
  return (
    <Container dial={5} tag="section" row>
      <LeftSide>
        {brandIcon ? (
          <Fade bottom distance="3.75rem">
            <Brand src={brandIcon} width="40" height="46" alt="Zucchetti" />
          </Fade>
        ) : null}
        {title ? (
          <Fade bottom distance="3.75rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {description ? (
          <Fade bottom distance="3.75rem">
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </Fade>
        ) : null}
      </LeftSide>
      <RightSide>
        {image ? (
          <Fade>
            <LazyLoad>
              <Illustration {...image} />
            </LazyLoad>
          </Fade>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 7.5rem 10vw;

  @media (max-width: 1199px) {
    padding-right: 4.861vw;
    padding-left: 4.861vw;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.5rem 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 70%;
  padding-right: 6.944vw;

  @media (max-width: 1023px) {
    width: auto;
    max-width: 600px;
    padding-right: 0;
    text-align: center;
  }
`

const Brand = styled.img`
  margin-bottom: 0.75rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 3.75rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-top: 1.625rem;
`

const RightSide = styled.div``

const Illustration = styled(Image)`
  @media (max-width: 1023px) {
    display: none;
  }
`
