import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { Image } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Props as ReviewProps, Review } from './Review'

export interface Props {
  description?: string
  reviews: ReviewProps[]
}

export const CustomerReviews = memo(function CustomerReviews({
  description,
  reviews,
}: Props) {
  if (reviews.length < 1) {
    return null
  }

  const [navigationMainSlider, setNavigationMainSlider] = useState<any | null>()
  const [navigationThumbsSlider, setNavigationThumbsSlider] = useState<
    any | null
  >()
  const mainSlider = useRef(null)
  const thumbsSlider = useRef(null)

  useEffect(() => {
    setNavigationMainSlider(mainSlider.current)
    setNavigationThumbsSlider(thumbsSlider.current)
  }, [])

  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.999"
        height="30"
        viewBox="0 0 33.999 30"
      >
        <path
          d="M16.938,35.883c-1.326-1.336-2.611-2.638-3.9-3.93-3.352-3.359-6.718-6.7-10.06-10.074A9.515,9.515,0,0,1,0,14.909,8.979,8.979,0,0,1,6.684,6.242a8.9,8.9,0,0,1,8.877,2.312c.462.442.874.938,1.384,1.489.483-.517.86-.942,1.265-1.36a9.149,9.149,0,0,1,14.922,2.574c1.55,3.376.949,6.6-1.234,9.55a9.656,9.656,0,0,1-.932,1.061Q24.139,28.7,17.312,35.539C17.213,35.638,17.108,35.73,16.938,35.883Z"
          transform="translate(0.001 -5.883)"
        />
      </svg>
      {description ? (
        <Fade bottom distance="3.75rem">
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Fade>
      ) : null}

      <Fade bottom distance="3.75rem">
        <Carousel>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="172"
            height="169.339"
            viewBox="0 0 172 169.339"
          >
            <path
              d="M89.609,149.046H32.545v-71.9C-21.1,70.3-3.406,9.238,49.664,19.509c16.549-25.679,56.494-25.679,73.043,0,52.5-10.272,70.76,50.787,16.549,57.635v71.9Zm-57.065,6.848H139.256c9.13,0,9.13,13.7,0,13.7H32.545C23.415,169.589,23.415,155.893,32.545,155.893Z"
              transform="translate(-0.042 -0.25)"
              fillRule="evenodd"
            />
          </svg>
          <Slider
            asNavFor={navigationThumbsSlider}
            autoplay={true}
            autoplaySpeed={5000}
            className="main-slider"
            dots={true}
            fade={true}
            pauseOnHover={true}
            ref={mainSlider}
          >
            {reviews.map((item, index) => (
              <Review key={index} {...item} />
            ))}
          </Slider>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="173.228"
            height="231.98"
            viewBox="0 0 173.228 231.98"
          >
            <path
              d="M-2031.445-1365.009l-28.54-57.884,13.787-28.456,45.229,68.585a16.851,16.851,0,0,1-5.562,24.078l-1.452.974a16.557,16.557,0,0,1-8.28,2.226A17,17,0,0,1-2031.445-1365.009Zm-89.975,7.055-1.453-.974a17,17,0,0,1-5.561-24.078l74.738-111.149-3.629-13.133c-1.692-6.079-.484-13.863,3.387-20.43l3.629-6.567,29.749-49.128a5.043,5.043,0,0,1,5.806-2.189,5.171,5.171,0,0,1,2.9,7.3s-20.075,36.969-25.881,47.67a3.255,3.255,0,0,0,1.211,4.378,3.462,3.462,0,0,0,4.354-1.217l28.058-46.453a5.043,5.043,0,0,1,5.8-2.189,5.174,5.174,0,0,1,2.9,7.3s-20.075,36.968-25.88,47.67a3.254,3.254,0,0,0,1.208,4.378,3.462,3.462,0,0,0,4.353-1.215c6.289-10.215,28.058-46.455,28.058-46.455a5.084,5.084,0,0,1,7.741-1.215c1.935,1.458,2.177,4.135.241,6.324l-27.33,50.345-3.629,6.567c-3.87,6.567-9.676,11.431-15.965,13.134l-13.059,3.4-58.292,120.633a16.348,16.348,0,0,1-14.985,9.573A16.987,16.987,0,0,1-2121.421-1357.954Zm31.927-124.525-3.629,1.946c-10.642,6.079-24.186,1.458-28.3-9.973l-27.816-71.505a19.288,19.288,0,0,1,8.224-23.59,6.15,6.15,0,0,1,8.225,1.946l38.456,59.1,25.156,38.183L-2082-1467.4Z"
              transform="translate(2151.075 1586.964)"
            />
          </svg>
        </Carousel>
        <ThumbsSlider
          asNavFor={navigationMainSlider}
          className="thumbs-slider"
          focusOnSelect={true}
          ref={thumbsSlider}
          slidesToShow={reviews.length}
          swipeToSlide={true}
          variableWidth={true}
        >
          {reviews.map((item, index) =>
            item.logo ? (
              <Image key={index} {...item.logo} />
            ) : (
              <Structure key={index}>{item.structure}</Structure>
            ),
          )}
        </ThumbsSlider>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  max-width: 956px;
  margin: 9rem auto;
  padding: 0 1.5rem;
  text-align: center;
  > svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
  }

  @media (max-width: 1023px) {
    margin: 5.625rem auto;
  }
`

const Description = styled.div`
  max-width: 668px;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 2.8125rem;
  margin: 0.75rem auto 0;
  b,
  strong {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
    font-weight: 600;
  }
`

const Carousel = styled.div`
  margin-top: 3.75rem;
  padding-bottom: 2.5rem;
  position: relative;
  > svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    position: absolute;
    &:first-of-type {
      top: -30px;
      left: 0;
    }
    &:last-of-type {
      right: 0;
      bottom: 0;
    }
  }

  @media (max-width: 1023px) {
    > svg {
      display: none;
    }
  }

  .main-slider {
    max-width: 760px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 2.361vw;
    box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 12px;
    margin: auto;
    z-index: 2;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    bottom: -2.5rem;
    li {
      width: 10px;
      height: 10px;
      margin: 0 0.625rem;
      &.slick-active {
        button {
          &:before {
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            opacity: 1;
          }
        }
      }
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          border: 1px solid ${({ theme }) => theme.colors.variants.neutralDark1};
          border-radius: 50%;
          opacity: 1;
        }
      }
    }
  }
`

const ThumbsSlider = styled(Slider)`
  margin-top: 5.5rem;
  .slick-track {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slick-slide {
    cursor: pointer;
    opacity: 0.6;
    margin: 0 1.5rem;
    &.slick-current {
      opacity: 1;
      img {
        filter: grayscale(0);
      }
      h5 {
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
      }
    }
    > div {
      display: flex;
      align-items: center;
      height: 7.5rem;
      img {
        filter: grayscale(100%);
      }
      h5 {
        color: ${({ theme }) => theme.colors.variants.neutralDark4};
      }
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Structure = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
`
